<template>
  <div>
    <div class="row mt-2 mx-2">
      <div class="col-12">
        <v-dialog
            v-model="dialog"
            persistent
            width="500"
            v-if="hasAccess('CAN_CREATE_ROLE')"
          >
            <template v-slot:activator="{ on: dialog, attrs }">
              <v-btn
                color="white"
                elevation="2"
                dark
                v-bind="attrs"
                v-on="{ ...dialog }"
              >
                <span class="primary--text"> ADD ROLE </span>
              </v-btn>
            </template>
            <v-card color="rgba(255,255,255,1)">
              <v-card-title class="text-h5 text-center">
                <h5 class="text-center primary--text">Add Role</h5>
              </v-card-title>
              <v-card-text>
                <v-text-field
                  label="Role Name"
                  v-model="roleName"
                  outlined
                  dense
                />
              </v-card-text>
              <v-card-actions class="mx-3">
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  class="white--text"
                  @click="dialog = false"
                  style="text-transform: unset"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="primary"
                  class="white--text"
                  @click="addRoleHandler"
                  style="text-transform: unset"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
        </v-dialog>
      </div>
    </div>
    <div class="row mt-2 mx-2">
      <div class="col-12">
        <v-card elevation="1">
          <v-toolbar flat outlined color="#f2f2f2">
            <v-toolbar-title>
              <span>
                <v-icon size="40" color="blue">
                  mdi-shield-key-outline
                </v-icon>
              </span>
              <span class="blue--text text-h5 font-weight-bold">Roles</span>
            </v-toolbar-title>
          </v-toolbar>
            <v-data-table :headers="headers" :items="roles" :search="search">
              <template v-slot:[`item.SN`]="{ item }">
                {{ roles.indexOf(item) + 1 }}
              </template>
              <template v-slot:[`item.permissions`]="{ item }">
                <v-btn
                  @click="goToPermissions(item)"
                  depressed
                  color="primary"
                  small
                  outlined
                >
                  Permissions
                </v-btn>
              </template>
              <template v-slot:[`item.users`]="{ item }">
                <v-btn
                  @click="goToAssignedUsers(item)"
                  depressed
                  color="primary"
                  small
                  outlined
                >
                  Assigned users
                </v-btn>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-menu app offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      slot="activator"
                      color="primary"
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon> mdi-dots-vertical </v-icon>
                    </v-btn>
                  </template>

                  <v-list dense>
                    <v-list-item link v-if="hasAccess('CAN_UPDATE_ROLE')">
                      <v-list-item-title>
                        <v-icon
                          style="height: 16px; font-size: 16px"
                          color="primary"
                        >
                          mdi-shield-key-outline
                        </v-icon>
                        <v-dialog v-model="dialog1" persistent max-width="40%">
                          <template v-slot:activator="{ on, attrs }">
                            <span
                              v-bind="attrs"
                              v-on="on"
                              class="primary--text mx-5"
                              style="text-transform: unset"
                              @click="takeId(item.uniqueId)"
                            >
                              Edit Role
                            </span>
                          </template>
                          <v-card color="rgba(255,255,255,1)">
                            <v-card-title class="text-h5 text-center">
                              <h5 class="text-center primary--text">
                                Edit Role
                              </h5>
                            </v-card-title>
                            <v-card-text>
                              <v-text-field
                                label="Role Name"
                                v-model="item.roleName"
                                :value="item.roleName"
                                outlined
                                dense
                              />
                            </v-card-text>
                            <v-card-actions class="mx-3">
                              <v-spacer></v-spacer>
                              <v-btn
                                color="red"
                                class="white--text"
                                style="text-transform: unset"
                                @click="dialog1 = false"
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                color="primary"
                                class="white--text"
                                style="text-transform: unset"
                                @click="editRole(item)"
                              >
                                Save
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item link @click="deleteRole(item.uniqueId)" v-if="hasAccess('CAN_DELETE_ROLE')">
                      <v-list-item-title>
                        <span><v-icon color="red">mdi-delete</v-icon></span
                        ><span class="red--text mx-5">Delete role</span>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {

  data: () => ({
    dialog: false,
    dialog1: false,
    search: "",
    roleName: "",
    permissionName: "",
    permissionCode: "",
    permissionDescription: "",
    roleId: "",
    headers: [
      {
        text: "SN",
        align: "start",
        sortable: false,
        value: "SN",
      },
      { text: "Role name", value: "roleName" },
      { text: "Permissions", value: "permissions" },
      { text: "Users", value: "users" },
      { text: "Actions", value: "actions", sortable: false },
    ],
  }),
  methods: {
    async addRoleHandler() {
      var data = {
        roleName: this.roleName,
      };

      await this.$store.dispatch("addRole", data);

      this.roleName = "";
      this.dialog = false;
    },
    takeId(uniqueId) {
      this.roleId = uniqueId;
    },
    editRole(item) {
      var data = {
        roleName: item.roleName,
        uniqueId: item.uniqueId,
      };

      this.$store.dispatch("editRole", data);

      this.dialog1 = false;
    },
    deleteRole(uniqueId) {
      var data = {
        uniqueId: uniqueId,
      };

      this.$store.dispatch("deleteRole", data);
    },
    addPermissionHandler() {
      var data = {
        permissionName: this.permissionName,
        permissionCode: this.permissionCode,
        permissionDescription: this.permissionDescription,
      };
      this.$store.dispatch("addPermission", data);
      this.dialog1 = false;
    },

    goToPermissions(item) {
      this.$router.push("/permissions/" + item.uniqueId);
    },
    goToAssignedUsers(item){
      this.$router.push('/users-assigned-to-role/' + item.uniqueId);
    }
  },
  computed: {
    ...mapGetters({
      rolesInMyInstitution: "getRoleInMyInstitution",
      roles: "getRoles",
    }),
  },
  mounted() {
    this.$store.dispatch("fetchRoleInMyInstitution");
  },
};
</script>

<style scoped>
.background {
  /* background-image: url("../../assets/images/background1.png"); */
  background-color: aliceblue;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>