var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row mt-2 mx-2"},[_c('div',{staticClass:"col-12"},[(_vm.hasAccess('CAN_CREATE_ROLE'))?_c('v-dialog',{attrs:{"persistent":"","width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"white","elevation":"2","dark":""}},'v-btn',attrs,false),Object.assign({}, dialog)),[_c('span',{staticClass:"primary--text"},[_vm._v(" ADD ROLE ")])])]}}],null,false,3896913344),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"color":"rgba(255,255,255,1)"}},[_c('v-card-title',{staticClass:"text-h5 text-center"},[_c('h5',{staticClass:"text-center primary--text"},[_vm._v("Add Role")])]),_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Role Name","outlined":"","dense":""},model:{value:(_vm.roleName),callback:function ($$v) {_vm.roleName=$$v},expression:"roleName"}})],1),_c('v-card-actions',{staticClass:"mx-3"},[_c('v-spacer'),_c('v-btn',{staticClass:"white--text",staticStyle:{"text-transform":"unset"},attrs:{"color":"red"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"white--text",staticStyle:{"text-transform":"unset"},attrs:{"color":"primary"},on:{"click":_vm.addRoleHandler}},[_vm._v(" Save ")])],1)],1)],1):_vm._e()],1)]),_c('div',{staticClass:"row mt-2 mx-2"},[_c('div',{staticClass:"col-12"},[_c('v-card',{attrs:{"elevation":"1"}},[_c('v-toolbar',{attrs:{"flat":"","outlined":"","color":"#f2f2f2"}},[_c('v-toolbar-title',[_c('span',[_c('v-icon',{attrs:{"size":"40","color":"blue"}},[_vm._v(" mdi-shield-key-outline ")])],1),_c('span',{staticClass:"blue--text text-h5 font-weight-bold"},[_vm._v("Roles")])])],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.roles,"search":_vm.search},scopedSlots:_vm._u([{key:"item.SN",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.roles.indexOf(item) + 1)+" ")]}},{key:"item.permissions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"depressed":"","color":"primary","small":"","outlined":""},on:{"click":function($event){return _vm.goToPermissions(item)}}},[_vm._v(" Permissions ")])]}},{key:"item.users",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"depressed":"","color":"primary","small":"","outlined":""},on:{"click":function($event){return _vm.goToAssignedUsers(item)}}},[_vm._v(" Assigned users ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"app":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"slot":"activator","color":"primary","icon":""},slot:"activator"},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[(_vm.hasAccess('CAN_UPDATE_ROLE'))?_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',[_c('v-icon',{staticStyle:{"height":"16px","font-size":"16px"},attrs:{"color":"primary"}},[_vm._v(" mdi-shield-key-outline ")]),_c('v-dialog',{attrs:{"persistent":"","max-width":"40%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"primary--text mx-5",staticStyle:{"text-transform":"unset"},on:{"click":function($event){return _vm.takeId(item.uniqueId)}}},'span',attrs,false),on),[_vm._v(" Edit Role ")])]}}],null,true),model:{value:(_vm.dialog1),callback:function ($$v) {_vm.dialog1=$$v},expression:"dialog1"}},[_c('v-card',{attrs:{"color":"rgba(255,255,255,1)"}},[_c('v-card-title',{staticClass:"text-h5 text-center"},[_c('h5',{staticClass:"text-center primary--text"},[_vm._v(" Edit Role ")])]),_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Role Name","value":item.roleName,"outlined":"","dense":""},model:{value:(item.roleName),callback:function ($$v) {_vm.$set(item, "roleName", $$v)},expression:"item.roleName"}})],1),_c('v-card-actions',{staticClass:"mx-3"},[_c('v-spacer'),_c('v-btn',{staticClass:"white--text",staticStyle:{"text-transform":"unset"},attrs:{"color":"red"},on:{"click":function($event){_vm.dialog1 = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"white--text",staticStyle:{"text-transform":"unset"},attrs:{"color":"primary"},on:{"click":function($event){return _vm.editRole(item)}}},[_vm._v(" Save ")])],1)],1)],1)],1)],1):_vm._e(),(_vm.hasAccess('CAN_DELETE_ROLE'))?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.deleteRole(item.uniqueId)}}},[_c('v-list-item-title',[_c('span',[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-delete")])],1),_c('span',{staticClass:"red--text mx-5"},[_vm._v("Delete role")])])],1):_vm._e()],1)],1)]}}],null,true)})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }